import React, { useState } from 'react'
import cn from 'classnames'

import { Link } from 'gatsby'

import Image, { Sizes } from '@components/molecules/image/image'
import MobileMenu from '@components/molecules/mobile-menu/mobile-menu'

import burgerStyles from './burger.module.scss'
import styles from './header.module.scss'

const links = [
    {
        label: 'Courses',
        link: '/courses'
    },
    {
        label: 'About',
        link: '/about'
    },
    {
        label: 'Businesses',
        link: '/businesses'
    },
    {
        label: 'Clubs',
        link: '/clubs'
    },
    {
        label: 'Get In Touch',
        link: '/contact'
    }
]

const Header = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    const hamburgerStyles = cn(styles.hamburger, burgerStyles.hamburger, burgerStyles.hamburgerArrowturn, menuOpen && burgerStyles.isActive)

    const handleBurgerClick = () => {
        document.body.style.overflow = !menuOpen ? 'hidden' : 'scroll'
        setMenuOpen(!menuOpen)
    }

    const handleMobileLinkClick = () => {
        setMenuOpen(false)
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: styles.active } : {}
    }

    return (
        <header className={styles.header}>
            <Link className={styles.logoContainer} to="/">
                <Image className={styles.logo} url="https://a.storyblok.com/f/88565/4724x1181/4ff1c7b6f2/logo.png" size={Sizes.Logo} />
            </Link>
            <ul className={styles.nav}>
                {links.map(({ label, link }) => (
                    <Link key={label} getProps={isPartiallyActive} to={link}>
                        <li>{label}</li>
                    </Link>
                ))}
            </ul>

            {menuOpen && <MobileMenu onClick={handleMobileLinkClick} links={links} />}

            <button aria-label="Toggle mobile menu" onClick={handleBurgerClick} className={hamburgerStyles} type="button">
                <span className={burgerStyles.hamburgerBox}>
                    <span className={burgerStyles.hamburgerInner}></span>
                </span>
            </button>
        </header>
    )
}

export default Header

import React from 'react'
import { Link } from 'gatsby'

import Text from '@components/atoms/text/text'
import Heading, { Types } from '@components/atoms/heading/heading'

import styles from './footer.module.scss'

interface Props {
    courses: Course[]
}

const Footer = ({ courses }: Props) => {
    const upcomingCourses = courses?.filter(({ content: { availableCourses } }) => !!availableCourses.length).slice(0, 4)
    return (
        <footer className={styles.footer}>
            <ul className={styles.list}>
                <li className={styles.usefulContainer}>
                    <ul className={styles.usefulLinks}>
                        <li className={styles.heading}>
                            <Heading type={Types.H3} text="Useful Links" />
                        </li>
                        <li>
                            <Link activeClassName={styles.activeLink} to="/about">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.activeLink} to="/businesses">
                                Workplace Courses
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.activeLink} to="/clubs">
                                Clubs
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.activeLink} to="/contact">
                                Contact
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.activeLink} to="/cookies">
                                Cookie Policy
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className={styles.upcomingContainer}>
                    <ul className={styles.upcoming}>
                        <li className={styles.heading}>
                            <Heading type={Types.H3} text="Upcoming Courses" />
                        </li>
                        {upcomingCourses?.map(({ full_slug, name }) => (
                            <li key={full_slug}>
                                <Link activeClassName={styles.activeLink} to={`/${full_slug}`}>
                                    <span>-</span>
                                    {name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className={styles.contactContainer}>
                    <ul className={styles.contactList}>

                        <li>
                            <a href="tel:07368880335">
                                <i className="icon icon-mobile"></i>07368880335
                            </a>
                        </li>

                        <li>
                            <a
                                rel="me"
                                href="https://www.facebook.com/pages/category/Safety---First-Aid-Service/M-W-Training-113324096790413/"
                            >
                                <i className="icon icon-facebook"></i>Facebook
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <a
                className={styles.badgeContainer}
                rel="noopener nofollow"
                href="https://www.protrainings.uk/centres/91549?a=4677"
                target="_blank"
            >
                <img
                    className={styles.badge}
                    loading="lazy"
                    src="https://d3imrogdy81qei.cloudfront.net/centre_badges/465/91549.png"
                    alt="ProTrainings Approved Centre #91549"
                />
            </a>
            <Text className={styles.copyright}>&copy; MW-Training 2020</Text>
        </footer>
    )
}

export default Footer

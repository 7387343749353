import React from 'react'
import cn from 'classnames'

import styles from './text.module.scss'

type Props = {
    className?: string
    children: React.ReactNode
}

export default ({ className, children }: Props) => {
    const style = cn(styles.text, className)
    return <p className={style}>{children}</p>
}

import React, { DetailedHTMLProps, ButtonHTMLAttributes } from 'react'
import cn from 'classnames'

import Text from '@components/atoms/text/text'

import styles from './button.module.scss'

export enum Icons {
    Send = 'icon-send',
    ArrowDown = 'icon-down',
    ArrowRight = 'icon-right'
}

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    className?: string
    icon?: Icons
    text: string
}

const Button = ({ className, icon, text, ...other }: Props) => {
    const containerClass = cn(styles.button, className)
    return (
        <button className={containerClass} {...other}>
            <Text className={styles.buttonText}>{text}</Text>
            {!!icon && (
                <div className={styles.buttonIcon}>
                    <i className={`icon ${icon}`}></i>
                </div>
            )}
        </button>
    )
}

export default Button

import React, { useState, useEffect } from 'react'
import Button from '@components/atoms/button/button'
import { Link } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import { setCookie, getCookie } from '@helpers/cookies'

import Text from '@components/atoms/text/text'

import styles from './cookie-modal.module.scss'

const CookieModal = () => {
    const [visible, setVisible] = useState(false)

    const handleClick = () => {
        setVisible(false)
        setCookie('_ca', 'true', 365)
    }

    useEffect(() => {
        setVisible(!getCookie('_ca'))
    }, [])

    return (
        <CSSTransition
            in={visible}
            timeout={200}
            unmountOnExit={true}
            classNames={{
                exit: styles.leave,
                exitActive: styles.leaveActive,
                exitDone: styles.leaveDone
            }}
        >
            <div className={styles.container}>
                <Text className={styles.text}>
                    <strong>We Use Cookies!</strong> We use cookies to enhance your browsing experience and analyse our site traffic. By
                    continuing to use this site you consent to our use of cookies. Read More how we use cookies and how you control them in
                    our <Link to="/cookies">Cookie Policy</Link>
                    <Button aria-label="Dismiss cookie modal" text="Accept Cookies" className={styles.button} onClick={handleClick} />
                </Text>
            </div>
        </CSSTransition>
    )
}

export default CookieModal

import React from 'react'
import cn from 'classnames'

import Header from '@components/organisms/header/header'
import CookieModal from '@components/molecules/cookie-modal/cookie-modal'
import Footer from '@components/organisms/footer/footer'

import styles from './container.module.scss'

type Props = {
    children: React.ReactNode
    className?: string
    courses: Course[]
}

export default ({ className, children, courses }: Props) => {
    const mainClass = cn(styles.main, className)
    return (
        <div className={styles.container}>
            <Header />
            <main className={mainClass}>{children}</main>
            <Footer courses={courses} />
            <CookieModal />
        </div>
    )
}

import React from 'react'
import { Link } from 'gatsby'

import styles from './mobile-menu.module.scss'

type Props = {
    links: NavLink[]
    onClick?: () => void
}

const MobileMenu = ({ links, onClick }: Props) => {
    const handleLinkClick = () => {
        document.body.style.overflow = 'scroll'
        onClick?.()
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: styles.active } : {}
    }

    return (
        <div className={styles.mobileMenu}>
            <ul className={styles.list}>
                {links.map(({ label, link }) => (
                    <Link getProps={isPartiallyActive} onClick={handleLinkClick} key={link} to={link}>
                        <li className={link}>{label}</li>
                    </Link>
                ))}
            </ul>
        </div>
    )
}

export default MobileMenu

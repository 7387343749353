import React from 'react'
import cn from 'classnames'

import styles from './heading.module.scss'

export enum Types {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3'
}

type Props = {
    className?: string
    text: string
    type?: Types
}

export default ({ className, text, type = Types.H1 }: Props) => {
    const style = cn(styles.heading, className)
    return React.createElement(type, { className: style }, text)
}

import React from 'react'
import cn from 'classnames'

import styles from './image.module.scss'

interface Props {
    className?: string
    image?: Image
    url?: string
    size: Sizes
}

const BASE = '//img2.storyblok.com'

export enum Sizes {
    Logo = '720x180',
    TileAvailableCourse = '500x450',
    TileCourse = '250x141',
    TilePromoted = '1000x563',
    Default = '1000x484'
}

const Image = ({ className, url, size = Sizes.Default, image }: Props) => {
    const src = image?.filename ?? url
    const logoSrc = (format) => `${BASE}/${size}/filters:format(${format})${src?.replace('https://a.storyblok.com', '')}`
    const imageStyles = cn(styles.image, className)
    return (
        <picture className={imageStyles}>
            <source srcSet={logoSrc('webp')} type="image/webp" />
            <img loading="lazy" alt={image?.alt ?? 'MW Training - First aid courses'} src={logoSrc('jpeg')} />
        </picture>
    )
}

export default Image
